import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import Question from '../question/Question';
import Survey from '../survey/Survey';
import QuestionData from '../../data/QuestionData';
import AnswerData from '../../data/AnswerData';

export default function App() {
  return (
    <Router>
      <div className="App">
        {/* <nav className="navigation">
          <ul>
            <li>
              <Link to="/">Splash</Link>
            </li>
            <li>
              <Link to="/Rules">Rules</Link>
            </li>
            <li>
              <Link to="/Quiz">Quiz</Link>
            </li>
          </ul>
        </nav> */}

        <Switch>
          <Route path="/Quiz">
            <Quiz />
          </Route>
          <Route path="/Rules">
            <Rules />
          </Route>
          <Route path="/">
            <Splash />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Splash() {
  return (
    <div className="splash-screen">
      <span className="be-inspired"></span>
      <Link className="splash-link" to="/Rules"></Link>
    </div>
  );
}

function Quiz() {
  return (
    <div className="survey">
      <Survey questions = {
                getQuestions(10)
            }/>
    </div>
    );
}

function getQuestions(num){
  var allQuestions = [
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Seul",false), new AnswerData("Tokio",true), new AnswerData("Istanbul",false)], "tokyo"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Dubai",true), new AnswerData("Moskva",false), new AnswerData("Pariz",false)], "dubai"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("London",false), new AnswerData("Milano",false), new AnswerData("Njujork",true)], "ny"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Milano",false), new AnswerData("Istanbul",true), new AnswerData("Moskva",false)], "istanbul"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Pariz",false), new AnswerData("Seul",true), new AnswerData("Moskva",false)], "seul"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Seul",false), new AnswerData("Istanbul",true), new AnswerData("Hong Kong",false)], "istanbul"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Pariz",true), new AnswerData("Seul",false), new AnswerData("Moskva",false)], "paris"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Hong Kong",true), new AnswerData("Moskva",false), new AnswerData("Njujork",false)], "hk"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Ankara",false), new AnswerData("Berlin",true), new AnswerData("Rim",false)], "berlin"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Monako",true), new AnswerData("Seul",false), new AnswerData("Moskva",false)], "monako"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Seul",true), new AnswerData("Berlin",false), new AnswerData("Rim",false)], "seul"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Seul",false), new AnswerData("Tokio",true), new AnswerData("Istanbul",false)], "tokyo2"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Dubai",true), new AnswerData("Moskva",false), new AnswerData("Pariz",false)], "dubai2"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("London",false), new AnswerData("Milano",false), new AnswerData("Njujork",true)], "ny2"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Milano",false), new AnswerData("Istanbul",true), new AnswerData("Moskva",false)], "istanbul2"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Pariz",false), new AnswerData("Seul",true), new AnswerData("Moskva",false)], "seul2"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Seul",false), new AnswerData("Istanbul",true), new AnswerData("Hong Kong",false)], "istanbul2"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Pariz",true), new AnswerData("Seul",false), new AnswerData("Moskva",false)], "paris2"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Hong Kong",true), new AnswerData("Moskva",false), new AnswerData("Njujork",false)], "hk2"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Tokio",false), new AnswerData("Berlin",true), new AnswerData("Rim",false)], "berlin2"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Monako",true), new AnswerData("Seul",false), new AnswerData("Moskva",false)], "monako2"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Seul",true), new AnswerData("Berlin",false), new AnswerData("Rim",false)], "seul2"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Seul",false), new AnswerData("Tokio",true), new AnswerData("Istanbul",false)], "tokyo3"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Dubai",true), new AnswerData("Moskva",false), new AnswerData("Pariz",false)], "dubai3"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("London",false), new AnswerData("Ankara",false), new AnswerData("Njujork",true)], "ny3"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Milano",false), new AnswerData("Istanbul",true), new AnswerData("Moskva",false)], "istanbul3"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Pariz",false), new AnswerData("Seul",true), new AnswerData("Moskva",false)], "seul3"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Seul",false), new AnswerData("Istanbul",true), new AnswerData("Hong Kong",false)], "istanbul3"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Pariz",true), new AnswerData("Seul",false), new AnswerData("Moskva",false)], "paris3"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Hong Kong",true), new AnswerData("Moskva",false), new AnswerData("Njujork",false)], "hk3"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Tokio",false), new AnswerData("Berlin",true), new AnswerData("Rim",false)], "berlin3"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Monako",true), new AnswerData("Seul",false), new AnswerData("Moskva",false)], "monako3"),
    new QuestionData("Koja je metropola prikazana na slici?", [new AnswerData("Seul",true), new AnswerData("Berlin",false), new AnswerData("Rim",false)], "seul3"),

]

  var newQuestions = [];
  while(num > 0){
    newQuestions.push(allQuestions.splice(Math.floor(Math.random() * allQuestions.length), 1)[0]);
    num--;
  }

  return newQuestions;
}

function Rules() {
  return (
  <div className="row rules-of-game">
          <Link className="splash-link" to="/Quiz"></Link>
    <span className="the-rules">
    Nagrađujemo one s kosmopolitskim duhom i znanjem!
<br /><br />

Prikazat će vam se 10 fotografija iz različitih svjetskih metropola i više
odgovora za svaku fotografiju.

<br /><br />

Ako prepoznate i pogodite koja je metropola na fotografiji osvojit ćete bodove.
<br /><br />

Više bodova, vrijednija nagrada!
<br /><br />

<b>Sretno!</b>
    </span>
  </div>
  );
}


